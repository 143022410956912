import axios from "axios";



const BaseUrl = "https://oralshow.online/api/v1"
// const BaseUrl = "http://localhost:8080/api/v1"

export function fetchData(apiName: string, params: any, onDataReceived: (data: string, status: number) => void): void {
    const deviceId = localStorage.getItem("deviceId");

    const reqParams = { deviceId: deviceId, ...params };
    const urlString = BaseUrl + apiName;

    try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', urlString, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        const token = localStorage.getItem("token") ?? "";
        console.log('token', token)
        xhr.setRequestHeader('Authorization', 'Bearer ' + token)
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 3) {
                const textData = xhr.responseText;
                onDataReceived(textData, xhr.status); // 调用回调函数处理数据
            } else if (xhr.readyState === 4) {
                console.log('Data stream ended');
                 // 现在请求已经完成，可以根据状态码处理不同情况
                 if (xhr.status >= 200 && xhr.status < 300) {
                    // 请求成功，处理成功的逻辑
                    console.log('Request succeeded with status:', xhr.status,xhr.responseText);
                    // 通常在这里调用处理成功响应的函数
                    onDataReceived(xhr.responseText, xhr.readyState);
                } else if (xhr.status === 0) {
                    // 处理意外关闭的情况
                    console.error('Connection closed unexpectedly.');
                    // 考虑添加重试请求的代码或者其他恢复措施
                    onDataReceived('An error occurred, try again', 500);
                } else {
                    // 请求失败，处理失败的逻辑
                    console.error('Request failed with status:', xhr.status);
                    // 在这里调用处理错误响应的函数
                    onDataReceived(`Request failed with status: ${xhr.status}`, xhr.status);
                }
            }
        };

        xhr.send(JSON.stringify(reqParams));
    } catch (error) {
        console.error('XMLHttpRequest failed:', error);
        onDataReceived('An error occurred, trying',500)

    }
}




export function sendPost<T = any>(apiName: string, params?: any): Promise<T> {

    return new Promise<T>(async (resolve, reject) => {

        const headers = {
            'Content-Type': 'application/json'
        }
        const deviceId = localStorage.getItem("deviceId");

        const reqParams = Object.assign({ deviceId: deviceId }, params)
        const bodyJsonStr = JSON.stringify(reqParams)
        const urlString = BaseUrl + apiName
        console.log('url', urlString);

        fetch(urlString, {
            method: "POST",
            headers: headers,
            body: bodyJsonStr
        }).then((response: any) => {
            console.log(apiName, response);
            if (response.status === 200 ) {
                return response.json()
            } else {
                throw 'Empty response or non-200 status'
            }
        }).then(async res => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })

    })


}


export function sendGet<T = any>(apiName: string, code: string): Promise<T> {
    const urlString = `${BaseUrl}${apiName}`;

    return axios.get(urlString).then(response => {
        // 您可以在这里直接处理成功的响应
        return response.data;
    }).catch(error => {
        // 错误处理
        throw error;
    });
}

export function getCountryCode() {
    return fetch('https://ipinfo.io/json/')
        .then(async response => {
            if (!response.ok) {

                throw new Error('Network response was not ok');
            }
            const responseText = await response.text()
            // console.log('getCountryCode',responseText);

            return JSON.parse(responseText);
        })
        .then(data => {
            const countryCode = data.country;
            return countryCode;
        })
        .catch(error => {
            console.log('Error fetching country code:', error);
        });
}
