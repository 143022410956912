import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { sendPost } from '../../net';

function LoginPage() {
  const [isPhoneLogin, setIsPhoneLogin] = useState(true);
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [countdown, setCountdown] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const navigate = useNavigate();
  const [receiveCode, setReceiveCode] = useState('')


  const handleLogin = async () => {
    if (receiveCode.length !== 6 || code.length !== 6 || receiveCode !== code) {
      alert('验证码不正确～')
      return
    }

    const param = {
      deviceId: isPhoneLogin ? phone : email,
      isWeb: true,
    };

    try {
      const data = await sendPost('/login', param);
      console.log('login data',data);
      
      if (data.code === '0') {
        localStorage.setItem('token', data.token);
        localStorage.setItem('deviceId', isPhoneLogin ? phone : email);

        navigate('/chat');
      } else {
        alert('登录失败');
      }
    } catch (error) {
      alert('登录请求失败');
      console.error('Login error:', error);
    }

    resetFields();
  };

  const resetFields = () => {
    setCode('');
    setCountdown(60);
    setIsCounting(false);
  };

  const sendVerificationCode = async () => {
    const endpoint = isPhoneLogin ? '/sms/send' : '/email/send';
    setIsCounting(true);
    setCountdown(60);

    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          setIsCounting(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    const param = isPhoneLogin
      ? { countryCode: '86', phoneNo: phone, code }
      : { email, code };

    try {
      const data = await sendPost(endpoint, param);
      console.log('Send verification code', data);

      if (data.status == 1) {
        setReceiveCode(data.code)
      }
    } catch (error) {
      alert('发送验证码请求失败');
      clearInterval(timer);
      setIsCounting(false);
      console.error('Send verification code error:', error);
    }
  };

  const renderInputFields = () => {
    return isPhoneLogin ? (
      <>
        <div className='input-account'>
          <input
            type="text"
            placeholder="请输入手机号"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
          <button onClick={sendVerificationCode} disabled={isCounting}>
            {isCounting ? `${countdown}秒` : '发送验证码'}
          </button>
        </div>
        <div className="code-container">
          <input
            type="text"
            placeholder="请输入验证码"
            value={code}
            onChange={e => setCode(e.target.value)}
          />
        </div>
      </>
    ) : (
      <>
        <div className='input-account'>
          <input
            type="email"
            placeholder="请输入邮箱"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button onClick={sendVerificationCode} disabled={isCounting}>
            {isCounting ? `${countdown}秒` : '发送验证码'}
          </button>
        </div>
        <div className="code-container">
          <input
            type="text"
            placeholder="请输入验证码"
            value={code}
            onChange={e => setCode(e.target.value)}
          />
        </div>
      </>
    );
  };

  return (
    <div className="container">
      <h1>登录</h1>
      <div className='panel'>
        <div className="segment-container">
          <div
            className={`segment ${isPhoneLogin ? 'active' : ''}`}
            onClick={() => setIsPhoneLogin(true)}
          >
            短信验证码登录
          </div>
          <div
            className={`segment ${!isPhoneLogin ? 'active' : ''}`}
            onClick={() => setIsPhoneLogin(false)}
          >
            邮箱验证码登录
          </div>
        </div>
        <div className="form-container">
          {renderInputFields()}
          <button className='loginButton' onClick={handleLogin}>
            登录
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
