import { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Image, ButtonGroup, Button } from 'react-bootstrap';
import './ChatPage.css';
import LoadingDots from '../../component/Loading';
import { fetchData, sendGet } from '../../net';
import { useNavigate } from 'react-router-dom';
import { isWeChatBrowser } from '../../util';

function ChatPage() {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const messageContainerRef = useRef(); // 创建消息容器的引用
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(""); // 图片预览的 URL



  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // 阻止默认行为，即插入新行
      handleSubmit(e);    // 提交表单
    }
    // 如果用户同时按下 Shift 和 Enter，允许换行，无需其他操作
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    const message = inputMessage;
    if (!message) return;
    const token = localStorage.getItem('token')
    if(!token){
      navigate('/login');
      return
    }


    addMessage("user", message);
    sendMessageToBackend(message);
    setInputMessage("");
    setImagePreviewUrl("")
  };

  const sendMessageToBackend = (message) => {
    addMessage("loading", "···");
    const deviceId = localStorage.getItem("deviceId");
    // Replace with your backend API endpoint
    fetchData('/chat', { "question": message, deviceId: deviceId, imageUrl: imagePreviewUrl }, (data, status) => {
      replaceMessage("received", data);
      if(status === 401){
        localStorage.removeItem('token')
        navigate('/login')
      }

    })


  };

  const addMessage = (sender, text) => {
    const newMessage = { sender, text };
    setMessages(prevMessages => [...prevMessages, newMessage]);
  };

  const replaceMessage = (sender, newText) => {
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      // 从后向前遍历数组来查找最后一个由特定 sender 发送的消息
      for (let i = updatedMessages.length - 1; i >= 0; i--) {
        if (updatedMessages[i].sender === 'loading' || updatedMessages[i].sender === 'received') {
          updatedMessages[i] = { sender: sender, text: newText, image: imagePreviewUrl };
          break; // 找到后立即退出循环
        }
      }
      console.log('sender', sender, newText);
      return updatedMessages;
    });
  };





  const getAvatarAndTitle = (sender) => {
    if (sender === 'user') {
      return {
        avatar: require('../../assets/icon.png'), // User avatar path
        title: 'You',
      };
    } else {
      return {
        avatar: require('../../assets/robot.png'), // AI assistant avatar path
        title: 'AI Assistant',
      };
    }
  };

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      const { scrollHeight, clientHeight } = messageContainerRef.current;
      const isContentOverflow = scrollHeight > clientHeight;
      if (isContentOverflow) {
        messageContainerRef.current.scrollTop = scrollHeight - clientHeight;
      }

    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 200); // setTimeout with 0 delay
    console.log('useEffect');
  }, [messages])


  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // 将文件转换为base64编码
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result); // 将base64字符串存储
    };
    reader.readAsDataURL(file);
    // 清空 fileInput 的值，这样即使选择相同的文件也会触发 onChange 事件
    fileInputRef.current.value = null;

  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
    setImagePreviewUrl('')
  };

  const handleRemoveImage = () => {
    setImagePreviewUrl(""); // 清除图片预览
  };

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === 'file' && item.type.startsWith('image/')) {
        const file = item.getAsFile();
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreviewUrl(e.target.result); // 将图片 base64 字符串设置为预览 URL
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // 登录后的界面
  return (
    <Container className="container">
      {messages.length === 0 ? (
        <div className="empty-chat">
          <Image className="empty-chat-img" src='logo@3x.png' alt="Logo" />
          <p className="empty-chat-text">说出你的需求，我们开始对话吧～</p>
        </div>
      ) : (
        <Row className="chat-content" ref={messageContainerRef}>
          <Col className="chat-messages" style={{ flex: 1 }}>
            <div className="chat-box">
              {messages.map((message, index) => {
                return (
                  <div key={index} className={`message`}>
                    <div className={`avatar`}>
                      <Image src={getAvatarAndTitle(message.sender).avatar} alt="" />
                      <div className="avatar-title">{getAvatarAndTitle(message.sender).title}</div>
                    </div>
                    {message.sender === 'loading' ? (
                      <div className="message-loading"><LoadingDots /></div>
                    ) : (
                      <div>
                        {message.image && (
                          <img src={message.image} alt="发送的图片" className="message-image" />
                        )}
                        <div className="message-text">{message.text}</div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      )}

      <Form onSubmit={handleSubmit} className="input-form" onPaste={handlePaste}>
        <div className="input-with-upload">
          <div className="upload-button-wrapper" onClick={handleFileUploadClick}>
            <Image src={require('../../assets/upload-icon.png')} className="upload-icon" />
            <input
              type="file"
              ref={fileInputRef}
              className="file-input"
              onChange={handleFileChange}
              accept="image/*"
            />
          </div>
          {imagePreviewUrl && (
            <div className="image-preview">
              <img src={imagePreviewUrl} alt="预览" className="preview-img" />
              <img
                src={require('../../assets/close.png')}
                alt="删除"
                className="remove-image-btn"
                onClick={handleRemoveImage}
              />
            </div>
          )}

          <Form.Control
            id="inputBox"
            className="input-box"
            as="textarea"
            placeholder="按Enter键发送，Shift+Enter可以换行哦～"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            rows={Math.max(inputMessage.split('\n').length, 1)}
          />
        </div>
        <Image
          className="send-button"
          src={require('../../assets/send.png')}
          onClick={handleSubmit}
        />
      </Form>
    </Container>
  );
}

export default ChatPage;
