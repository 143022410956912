import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import ChatPage from './pages/chat/ChatPage';
import LoginPage from './pages/login/LoginPage';

function App() {

  return (
    <div className="App">
      <Router>
          <Routes>
            <Route exact path="/login" element={<LoginPage  />} />
            <Route path="/" element={<ChatPage />} />
            <Route path="/chat" element={<ChatPage />} />

          </Routes>
        </Router>
    </div>
  );
}

export default App;
