import React, { useState, useEffect } from 'react';

function LoadingDots() {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prevDots => (prevDots.length < 3 ? prevDots + '·' : '·'));
        }, 500); // 每500毫秒更新一次

        return () => clearInterval(interval); // 组件卸载时清除定时器
    }, []);

    return <div>{dots}</div>;
}

export default LoadingDots;